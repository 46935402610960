export function useExcelParams(
  fieldsLabels: { [key: string]: string },
  nombre: string
) {
  return {
    labels: fieldsLabels,
    fields: Object.keys(fieldsLabels),
    generateName: () => nombre + new Date().getTime() + ".xlsx",
  };
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "card-body py-3" }
const _hoisted_10 = { class: "flex items-center justify-center h-100%" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_download_excel = _resolveComponent("download-excel")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_Page = _resolveComponent("Page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tabla.menores.titulo")), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("tabla.menores.numeroRegistros", { numero: _ctx.menores.length })), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_input, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              style: {"width":"300px"},
              placeholder: _ctx.$t('tabla.buscador.placeholder')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_download_excel, {
              data: _ctx.menores,
              labels: _ctx.labels,
              fields: _ctx.fields,
              generateName: _ctx.generateName
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_7, [
                  _createElementVNode("i", {
                    class: "fas fa-file-excel fs-3",
                    title: _ctx.$t('tabla.acciones.imprimir')
                  }, null, 8, _hoisted_8)
                ])
              ]),
              _: 1
            }, 8, ["data", "labels", "fields", "generateName"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            style: {"width":"100%"},
            stripe: "",
            data: _ctx.result,
            "default-sort": { prop: 'createdOn', order: 'descending' },
            onSortChange: _ctx.sortChange
          }, {
            empty: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                (_ctx.error)
                  ? (_openBlock(), _createBlock(_component_el_empty, {
                      key: 0,
                      description: _ctx.$t('tabla.errores.carga')
                    }, {
                      default: _withCtx(() => [
                        (_ctx.error)
                          ? (_openBlock(), _createBlock(_component_el_button, {
                              key: 0,
                              size: "small",
                              onClick: _ctx.fetch
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t("tabla.acciones.reintentar")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["description"]))
                  : (_openBlock(), _createBlock(_component_el_empty, { key: 1 }))
              ])
            ]),
            default: _withCtx(() => [
              (_ctx.isAdmin)
                ? (_openBlock(), _createBlock(_component_el_table_column, {
                    key: 0,
                    label: _ctx.$t('tabla.menores.cabecera.comarca'),
                    prop: "nombreComarca",
                    sortable: "",
                    "min-width": "150"
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.menores.cabecera.nombreCompleto'),
                sortable: "",
                prop: "nombreCompleto",
                "min-width": "150"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.menores.cabecera.fechaNacimiento'),
                sortable: "",
                prop: "fechaNacimiento",
                "min-width": "150"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.fechaNacimientoString), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.menores.cabecera.responsable'),
                sortable: "",
                prop: "responsable",
                "min-width": "150"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.menores.cabecera.familiaDni'),
                prop: "familiaDni",
                sortable: "",
                width: "120"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                width: "50",
                fixed: "right"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    size: "small",
                    type: "text",
                    onClick: ($event: any) => (_ctx.handleEdit(scope.$index, scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("tabla.acciones.fila.ver")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data", "onSortChange"])), [
            [_directive_loading, _ctx.isLoading]
          ]),
          _createVNode(_component_el_pagination, {
            class: "my-5 float-end",
            background: "",
            "page-size": 100,
            layout: "prev, pager, next",
            total: _ctx.menores.length,
            "hide-on-single-page": true,
            "current-page": _ctx.currentPage,
            onCurrentChange: _ctx.handleCurrentPageChange
          }, null, 8, ["total", "current-page", "onCurrentChange"])
        ])
      ])
    ]),
    _: 1
  }))
}
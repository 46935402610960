import { MenorModule } from "@/store/modules";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export function useMenores(comarcaId?: string) {
  const store = useStore();
  const module = getModule(MenorModule, store);

  const isLoading = ref(true);
  const error = ref();
  error.value = null;

  return {
    menores: computed(() => module.menores),
    isLoading,
    error,
    fetch: async () => {
      isLoading.value = true;
      error.value = null;
      try {
        await module.fetchAll(comarcaId);
      } catch (err) {
        error.value = err;
      } finally {
        isLoading.value = false;
      }
    },
  };
}

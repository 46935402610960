import { textSearch } from "@/assets/ts/_utils";
import { computed, ref } from "vue";

export function useSearchFilter(collection, searchOn: string[]) {
  const search = ref("");

  return {
    search,
    filteredCollection: computed(() => {
      if (!search.value) return collection.value;

      return collection.value?.filter((record) => {
        return searchOn.some((field) =>
          textSearch(record[field], search.value)
        );
      });
    }),
  };
}
